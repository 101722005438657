import axios from 'axios'


export const fetchOrganizations = () => {
    return axios.get('/api/organizations').then(resp => resp.data)
}
export const createOrganization = (organization) => {
    return axios.post(`/api/organizations`, organization).then(resp => resp.data)
}


export const getOrganization = organizationId => {
    return axios.get(`/api/organizations/${organizationId}`).then(resp => resp.data)
}

export const getOrganizationMembers = organizationId => {
    return axios.get(`/api/organizations/${organizationId}/members`).then(resp => resp.data)
}


export const inviteOrganizationMember = (organizationId, invite) => {
    return axios.post(`/api/organizations/${organizationId}/invitemember`, invite).then(resp => resp.data)
}

export const fetchOrganizationNewsGroups = (organizationId) => {
    return axios.get(`/api/organizations/${organizationId}/newsgroups`).then(resp => resp.data)
}

export const fetchOrganizationNewsGroup = (organizationId, newsgroupid) => {
    return axios.get(`/api/organizations/${organizationId}/newsgroups/${newsgroupid}`).then(resp => resp.data)
}

export const addOrganizationNewsGroup = (organizationId, newsgroup) => {
    return axios.post(`/api/organizations/${organizationId}/newsgroups`, newsgroup).then(resp => resp.data)
}

export const updateOrganizationNewsGroup = (organizationId, newsgroupid, newsgroup) => {
    return axios.post(`/api/organizations/${organizationId}/newsgroups/${newsgroupid}`, newsgroup).then(resp => resp.data)
}

export const deleteOrganizationNewsGroup = (organizationId, newsgroupid) => {
    return axios.delete(`/api/organizations/${organizationId}/newsgroups/${newsgroupid}`).then(resp => resp.data)
}

export const refreshOrganizationNewsGroupsData = (organizationId) => {
    return axios.get(`/api/organizations/${organizationId}/newsgroups/0/refresh`).then(resp => resp.data)
}

export const setAsDoneForToday = (organizationId, newsgroupid) => {
    return axios.post(`/api/organizations/${organizationId}/newsgroups/${newsgroupid}/donefortoday`).then(resp => resp.data)    
}


export const fetchOrganizationOutcasts = (organizationId) => {
    return axios.get(`/api/organizations/${organizationId}/outcasts`).then(resp => resp.data)
}

export const addOrganizationOutcast = (organizationId, newsgroup) => {
    return axios.post(`/api/organizations/${organizationId}/outcasts`, newsgroup).then(resp => resp.data)
}

export const deleteOrganizationOutcast = (organizationId, outcastId) => {
    return axios.delete(`/api/organizations/${organizationId}/outcasts/${outcastId}`).then(resp => resp.data)
}