import Home from './components/Home/Home.jsx'
import Invitations from './components/Users/Invitations.jsx'
import Activity from './components/Users/Activity.jsx'



import UserList from './components/Users/UserList.jsx'

import Settings from './components/Users/Settings.jsx'


import Organizations from './components/Organizations/Organizations.jsx'
import Organization from './components/Organizations/Organization.jsx'
import OrganizationMembers from './components/Organizations/Members.jsx'
import OrganizationNewsTracker from './components/Organizations/NewsTracker.jsx'
import OrganizationOutcasts from './components/Organizations/Outcasts.jsx'

var routes = [
    {
        path: "/",
        name: "Organizations",
        icon: "",
        component: Organizations,
        layout: "/app",
        exact: true
    },
    {
        path: "/user/settings",
        name: "Settings",
        icon: "",
        component: Settings,
        layout: "/app",
        exact: true
    },
    {
        path: "/user/invitations",
        name: "Invitations",
        icon: "",
        component: Invitations,
        layout: "/app",
        exact: true
    },
    {
        path: "/user/activity",
        name: "Activity",
        icon: "",
        component: Activity,
        layout: "/app",
        exact: true
    },
    /* ORGANIZATIONS */
    {
        path: "/organizations",
        name: "Organizations",
        icon: "",
        component: Organizations,
        layout: "/app"
    },
    {
        path: "/organizations/:organization_id",
        name: "Organization",
        icon: "",
        component: Organization,
        layout: "/app"
    },
    {
        path: "/organizations/:organization_id/members",
        name: "Organization Members",
        icon: "",
        component: OrganizationMembers,
        layout: "/app"
    },
    {
        path: "/organizations/:organization_id/newstracker",
        name: "Organization News Tracker",
        icon: "",
        component: OrganizationNewsTracker,
        layout: "/app"
    },
    {
        path: "/organizations/:organization_id/outcasts",
        name: "Organization Black list",
        icon: "",
        component: OrganizationOutcasts,
        layout: "/app"
    },
    /* ADMIN */
    {
        path: "/users",
        name: "Users",
        icon: "",
        component: UserList,
        layout: "/app"
    }
]

export default routes;
