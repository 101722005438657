import axios from 'axios'


axios.interceptors.request.use(
    function(config) {
      config.headers['Authorization'] =
        'bearer ' + localStorage.getItem('jwt-token')
      return config
    },
    function(error) {
      // Do something with request error
      return Promise.reject(error)
    }
  )

  export * from './usersapi'
  export * from './playersapi'
  export * from './listsapi'
  export * from './organizationsapi'