import React from 'react'
import { Formik } from 'formik';
import PropTypes from 'prop-types'
import * as api from '../../api/api';


import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Modal,
    Row,
    Col
} from "reactstrap";


class InviteMember extends React.Component {
    
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }


    closeModal() {
        this.props.closeModal();
    }

    handleSubmit(values, { setSubmitting }) {
        api.inviteOrganizationMember(this.props.match.params.organization_id, {
            email: values.email
        }).then(data => {
            this.props.notifyResult();
            this.props.closeModal();
        })
    }


    render() {
        return (<React.Fragment>
            <div className="modal-header">
                <h6 className="modal-title" id="modal-title-default">
                    Invite Member
        </h6>
            </div>
            <Formik initialValues={{ email: '' }}
                onSubmit={this.handleSubmit}>
                {({ values, handleSubmit, handleChange, handleBlur }) => (<form onSubmit={handleSubmit}>
                    <div className="modal-body">

                        <div>
                            <label htmlFor="email">Name</label>
                            <input id="email" name="email"  type="email" value={values.email} onChange={handleChange}
                                onBlur={handleBlur} />
                        </div>
                        

                    </div>
                    <div className="modal-footer">
                        <Button color="primary" type="submit">
                            Invite
                </Button>
                        <Button type="button" onClick={this.closeModal}>
                            Cancel
                </Button>
                    </div>
            </form>)}
            </Formik></React.Fragment >);
    }
}

InviteMember.propTypes = {
    notifyResult: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired
}

export default InviteMember;