import React from 'react'
import { Route, withRouter } from 'react-router-dom';

import MainHeader from '../headers/MainHeader.jsx'

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Container,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Modal,
    Row,
    Col,
    Table
} from "reactstrap";


import * as api from '../../api/api';

import * as date from '../../core/date';


class Invitations extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            invitations: []
        }

        this.getInvitations = this.getInvitations.bind(this)
    }

    componentDidMount() {
        this.getInvitations()
    }

    getInvitations() {
        api.getInvitations()
            .then(
                data => {
                    this.setState({ invitations: data.data })
                }
            )
    }

    render() {
        return (
            <React.Fragment>
                <MainHeader title="Invitations" />
                <Container className="mt--7" fluid>
                    <Row>
                        <Col md="8">
                            <Table className="align-items-center table-flush" responsive>
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col">Name</th>
                                        <th scope="col">Created At</th>
                                        <th scope="col">Status</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.invitations.map(u => {
                                        return (
                                            <tr key={u._id}>
                                                <th scope="row"><div onClick={() => this.goToOrganization(u.organization._id)}>{u.organization.name}</div></th>
                                                <td>{date.formatDate(u.createDate)}</td>
                                                <td>{u.status}</td>
                                                <td>Accept | Reject</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
        )
    }

}

export default withRouter(Invitations);