import React from 'react'
import { Route, withRouter } from 'react-router-dom';


import * as api from '../../api/api';

import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';


import CreateOrganization from './CreateOrganization.jsx';
import MainHeader from '../headers/MainHeader.jsx'

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Container,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Modal,
    Row,
    Col,
    Table
} from "reactstrap";

class Organizations extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            organizations: [],
            createOrganizationModalOpen: false
        }
        this.toggleModal = this.toggleModal.bind(this)
        this.updateOrganizations = this.updateOrganizations.bind(this)
        this.goToOrganization = this.goToOrganization.bind(this)
        this.goToMembers = this.goToMembers.bind(this)
        this.goToNewsTracker = this.goToNewsTracker.bind(this)
    }

    componentDidMount() {
        this.updateOrganizations();
    }

    toggleModal(state) {
        this.setState({
            [state]: !this.state[state]
        });
    }


    updateOrganizations() {
        api.fetchOrganizations().then(organizations => {
            this.setState({
                organizations: organizations.data
            })
        })
    }

    goToOrganization(organizationId) {
        this.props.history.push(`/app/organizations/${organizationId}`)
    }

    goToMembers(organizationId) {
        this.props.history.push(`/app/organizations/${organizationId}/members`)
    }

    goToPlayers(organizationId) {
        this.props.history.push(`/app/organizations/${organizationId}/players`)
    }


    goToNewsTracker(organizationId) {
        this.props.history.push(`/app/organizations/${organizationId}/newstracker`)
    }

    render() {
        return (
            <React.Fragment>
                <MainHeader title="Organizations" />
                <Container className="mt--7" fluid>
                    <Row>
                        <div className=" col">
                            <Button
                                block
                                className="mb-3"
                                color="primary"
                                type="button"
                                onClick={() => this.toggleModal("createOrganizationModalOpen")}
                            >
                                Create Organization
                            </Button>
                            <Modal
                                className="modal-dialog-centered"
                                isOpen={this.state.createOrganizationModalOpen}
                                toggle={() => this.toggleModal("createOrganizationModalOpen")}
                            >
                                <CreateOrganization
                                    updateOrganizations={this.updateOrganizations}
                                    closeModal={() => this.toggleModal("createOrganizationModalOpen")}
                                />
                            </Modal>
                        </div>
                    </Row>
                    <Row>
                        <Col md="8">
                            <Table className="align-items-center table-flush" responsive>
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col">Name</th>
                                        <th scope="col">Type</th>
                                        <th scope="col">Members</th>
                                        <th scope="col">News Tracker</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.organizations.map(u => {
                                        return (
                                            <tr key={u._id}>
                                                <th scope="row"><div onClick={() => this.goToOrganization(u._id)}>{u.name}</div></th>
                                                <td>{u.type}</td>
                                                <td><div className="link" onClick={() => this.goToMembers(u._id)}>Members</div></td>
                                                <td><div className="link" onClick={() => this.goToNewsTracker(u._id)}>News Tracker</div></td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
        )
    }
}

export default withRouter(Organizations);