import React from 'react'

import { Switch, Route, useRouteMatch, useParams, withRouter } from 'react-router-dom'


export const mapRoutes = (routes, layout) => {
    return routes.map((prop, key) => {
        if (prop.layout === layout) {
            return (
                <Route
                    path={prop.layout + prop.path}
                    component={prop.component}
                    key={key}
                    exact={prop.exact ? prop.exact : true}
                />
            );
        } else {
            return null;
        }
    });
}