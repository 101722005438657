import React from 'react'
import { Route, withRouter } from 'react-router-dom';
import { CardBody } from 'reactstrap';
import MainHeader from '../headers/MainHeader.jsx'


class Activity extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            activities: []
        }
    }

    render() {
        return (
            <React.Fragment>
                <MainHeader title="Activity" />
                <Container className="mt--7" fluid>
                    <Row>
                        <Col md="8">
                            <Table className="align-items-center table-flush" responsive>
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col">Name</th>
                                        <th scope="col">Created At</th>
                                        <th scope="col">Status</th>
                                        <th scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.invitations.map(u => {
                                        return (
                                            <tr key={u._id}>
                                                <th scope="row"><div onClick={() => this.goToOrganization(u.organization._id)}>{u.organization.name}</div></th>
                                                <td>{date.formatDate(u.createDate)}</td>
                                                <td>{u.status}</td>
                                                <td>Accept | Reject</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
        )
    }
}

export default withRouter(Activity)