import React from 'react'
import { Route, withRouter } from 'react-router-dom';

import * as api from '../../api/api';


import MainHeader from '../headers/MainHeader.jsx'
import CreateNewsGroup from './CreateNewsGroup.jsx';
import ArticlesModal from './ArticlesModal.jsx';

import { confirmAlert } from 'react-confirm-alert';

import { toast } from 'react-toastify';




import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Container,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Modal,
    Row,
    Col,
    Table
} from "reactstrap";

class NewsTracker extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            organization: {},
            newsgroups: [],
            newsgroup: null,
            createNewsGroupModalOpen: false,
            articlesModal: false
        }
        this.fetchOrganization = this.fetchOrganization.bind(this)
        this.fetchNewsGroups = this.fetchNewsGroups.bind(this)
        this.toggleModal = this.toggleModal.bind(this)
        this.syncData = this.syncData.bind(this)
        this.deleteNewsGroupConfirm = this.deleteNewsGroupConfirm.bind(this)
        this.deleteNewsGroup = this.deleteNewsGroup.bind(this)
        this.editNewsGroup = this.editNewsGroup.bind(this)
        this.setNgAdDoneForToday = this.setNgAdDoneForToday.bind(this)
        this.goToBlackList = this.goToBlackList.bind(this)
    }

    toggleModal(state) {
        this.setState({
            [state]: !this.state[state]
        });
    }

    componentDidMount() {
        this.fetchOrganization();
        this.fetchNewsGroups()
    }

    fetchOrganization() {
        api.getOrganization(this.props.match.params.organization_id).then(data => {
            this.setState({
                organization: data.data
            })
        })
    }

    fetchNewsGroups() {
        api.fetchOrganizationNewsGroups(this.props.match.params.organization_id).then(data => {
            //this.setState({ newsgroups: data.sort((a,b)=>{return a.name>b.name?-1:1}) })
            this.setState({ newsgroups: data })
        }
        )
    }


    goToBlackList() {
        this.props.history.push(`/app/organizations/${this.props.match.params.organization_id}/outcasts`)
    }

    syncData() {
        api.refreshOrganizationNewsGroupsData(this.props.match.params.organization_id)
    }

    deleteNewsGroupConfirm(newsGroupId) {
        confirmAlert({
            title: 'Confirm deletion',
            message: 'Are you sure you want to remove this News Group?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => this.deleteNewsGroup(newsGroupId)
                },
                {
                    label: 'No'
                }
            ]
        });
    }

    deleteNewsGroup(newsGroupId) {
        api.deleteOrganizationNewsGroup(this.props.match.params.organization_id, newsGroupId)
            .then(data => {
                this.fetchNewsGroups();
                toast("News Group deleted successfully.")
            })
            .catch(err => toast.error("Something went wrong while deleting this news group"))
    }

    editNewsGroup(newsgroup) {
        this.setState({ newsgroup }, () => this.toggleModal("createNewsGroupModalOpen"))
    }

    setNgAdDoneForToday(newsGroupId) {
        api.setAsDoneForToday(this.props.match.params.organization_id, newsGroupId).then(() => {this.fetchNewsGroups()})
    }

    render() {
        return (
            <React.Fragment>
                <MainHeader title={`Organization ${this.state.organization.name} - News Tracker`} />
                <Container className="mt--7" fluid>
                    <Row>
                        <Col>
                            <h1>News Groups</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="order-xl-2 mb-5 mb-xl-0" xl="3">
                            <Button
                                block
                                className="mb-3"
                                color="primary"
                                type="button"
                                onClick={() => this.setState({ newsgroup: null }, this.toggleModal("createNewsGroupModalOpen"))}
                            >
                                Create News Group
                            </Button>
                            <Modal
                                className="modal-dialog-centered"
                                isOpen={this.state.createNewsGroupModalOpen}
                                toggle={() => this.toggleModal("createNewsGroupModalOpen")}
                            >
                                <CreateNewsGroup
                                    updateNewsGroups={this.fetchNewsGroups}
                                    closeModal={() => this.toggleModal("createNewsGroupModalOpen")}
                                    newsGroupId={this.state.newsgroup !== null ? this.state.newsgroup.id : 0}
                                    {...this.props}

                                />
                            </Modal>
                        </Col>
                        <Col className="order-xl-2 mb-5 mb-xl-0" xl="3">
                            <Button
                                block
                                className="mb-3"
                                color="primary"
                                type="button"
                                onClick={() => this.syncData()}
                            >
                                Sync News Data
                            </Button>
                        </Col>
                        <Col className="order-xl-2 mb-5 mb-xl-0" xl="3">
                            <Button
                                block
                                className="mb-3"
                                color="primary"
                                type="button"
                                onClick={() => this.fetchNewsGroups()}
                            >
                                Fetch Data
                            </Button>
                        </Col>
                        <Col className="order-xl-2 mb-5 mb-xl-0" xl="3">
                            <Button
                                block
                                className="mb-3"
                                color="primary"
                                type="button"
                                onClick={() => this.goToBlackList()}
                            >
                                Black List
                            </Button>
                        </Col>

                    </Row>
                    <Row>
                        <Col xl="12">
                            <Table className="align-items-center table-flush" responsive>
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col">Name</th>
                                        <th scope="col">Keywords</th>
                                        <th scole="col">Languanges</th>
                                        <th scope="col">Last Sync Date</th>
                                        <th scope="col">Done for Today</th>
                                        <th scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.newsgroups.map(ng => {
                                        return (<tr key={ng._id}>
                                            <th className='link' onClick={() => this.setState({ newsgroup: ng }, this.toggleModal("articlesModal"))} scope="row">{ng.name}</th>
                                            <td>{ng.keywords.join(',')}</td>
                                            <td>{ng.language.join(',')}</td>
                                            <td>{ng.lastSyncDate}</td>
                                            <td>{ng.dailyCompletion?'Y':'N'}</td>
                                            <td>{ng.dailyCompletion?'':<><a href="#" onClick={() => this.setNgAdDoneForToday(ng._id)}>done</a> | </>}<a href="#" onClick={() => this.editNewsGroup(ng)}>edit</a> | <a href="#" onClick={() => this.deleteNewsGroupConfirm(ng._id)}>delete</a></td>
                                        </tr>)
                                    })}
                                </tbody>
                            </Table>
                        </Col>
                        {/* <Col xl="4">
                            {
                                (this.state.newsgroup !== null && false)  ?
                                    (<React.Fragment>
                                        <Row>
                                            <Col><h2>{this.state.newsgroup.name}</h2></Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <h3>Articles</h3>
                                                {
                                                    this.state.newsgroup.articles.map((a, k) => {
                                                        return <p key={a._id} style={{ background: k % 2 ? "#cecece" : "#f1f1f1" }}>- {a.title}<br /><a href={a.source} target="_blank">{a.source}</a></p>
                                                    })
                                                }
                                            </Col>
                                        </Row>
                                    </React.Fragment>) : null
                            }
                        </Col> */}
                    </Row>
                </Container>
                <Modal
                    className="modal-content"
                    isOpen={this.state.articlesModal}
                    toggle={() => this.toggleModal("articlesModal")}
                >
                    <ArticlesModal
                        updateNewsGroups={this.fetchNewsGroups}
                        closeModal={() => this.toggleModal("articlesModal")}
                        newsGroupId={this.state.newsgroup !== null ? this.state.newsgroup.id : 0}
                        {...this.props}

                    />
                </Modal>
            </React.Fragment>
        )
    }

}

export default withRouter(NewsTracker);
