import React from "react"
import PropTypes from "prop-types"
import * as api from "../../api/api";


import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Modal,
    Row,
    Col
} from "reactstrap";
import { isThisTypeNode } from "typescript";



class ArticlesModal extends React.Component {


    constructor(props) {
        super(props)
        this.state = {
            allArticles: [],
            articles: [],
            languages: [],
            chosenLanguage: "all",
            name: ''
        }
        this.filterArticles = this.filterArticles.bind(this)
        this.toClipboard = this.toClipboard.bind(this)
    }

    componentDidMount() {
        if (this.props.newsGroupId !== 0) {
            api.fetchOrganizationNewsGroup(this.props.match.params.organization_id, this.props.newsGroupId).then(data => {
                console.log(data)
                this.setState({
                    name: data.name,
                    languages: data.language,
                    allArticles: data.articles
                }, () => this.filterArticles("all"))
            })
        }
    }

    filterArticles(chosenLanguage) {
        this.setState({
            articles: (chosenLanguage === "all") ? this.state.allArticles : this.state.allArticles.filter(a => a.language === chosenLanguage),
            chosenLanguage
        }, console.log(chosenLanguage, this.state.articles, this.state.allArticles))
    }

    toClipboard() {
        const payload = this.state.articles.map(a => `- ${a.title}\n${window.location.origin.toString()}/api/url/${this.props.newsGroupId}/${a._id}\n`)
        const formatter = new Intl.DateTimeFormat(this.state.chosenLanguage === "all" ? 'en' : this.state.chosenLanguage, { month: 'long' });
        const month = formatter.format(new Date());
        const title = `*${this.state.name} - ${new Date().getDate()} ${month} ${new Date().getFullYear()}*`
        navigator.clipboard.writeText(`${title}\n\n ${payload.join('\n')}`)
    }



    render() {
        return (
            <React.Fragment>
                <div className="modal-header">
                    <h3 className="modal-title" id="modal-title-default">
                        Articles
                    </h3>
                </div>
                <Row>
                    <Col lg="1"></Col>
                    <Col lg="2">
                        Language
                    </Col>
                    <Col lg="6">
                        <span><a href="#" onClick={() => this.filterArticles("all")}>all</a></span> |
                        {this.state.languages.map(language => <span> <a href="#" key={language} onClick={() => this.filterArticles(language)}>{language}</a> </span>)}
                    </Col>
                    <Col lg="1"><a href="#" onClick={() => this.toClipboard()}>Copy</a></Col>
                </Row>
                <Row>
                    <Col lg="1"></Col>
                    <Col lg="10">
                        {
                            this.state.articles.map((a, k) => {
                                return <p key={a._id} style={{ background: k % 2 ? "#cecece" : "#f1f1f1" }}>- {a.title}<br /><a href={`${window.location.origin.toString()}/api/url/${this.props.newsGroupId}/${a._id}`} target="_blank">{window.location.origin.toString()}/api/url/{this.props.newsGroupId}/{a._id}</a></p>
                            })
                        }
                    </Col>
                    <Col lg="1"></Col>
                </Row>
            </React.Fragment>
        )
    }

}

ArticlesModal.propTypes = {
    closeModal: PropTypes.func.isRequired
}

export default ArticlesModal;
