import React from 'react'

import {
    Container,
    NavLink,
    Row,
    Col
} from "reactstrap";

import SiteNavBar from '../navbars/SiteNavbar.jsx'
import SiteFooter from '../footers/SiteFooter.jsx'


import publicRoutes from "../../publicRoutes.js";
import * as utils from '../../../common/utils.js'

import { Switch } from 'react-router-dom'


class Site extends React.Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        document.body.classList.add("bg-default");
    }

    componentWillUnmount() {
        document.body.classList.remove("bg-default");
    }

    render() {
        return (
            <React.Fragment>
                <div className="main-content">

                    <div className="text-left my-4">
                        <Container>
                            <Row className="justify-content-center">
                                <Col lg="6" md="6">
                                    <NavLink href="/site"><img src="/img/atheia.svg" alt="Atheia" /></NavLink>
                                </Col>
                                <Col lg="6" md="6">
                                    <SiteNavBar />
                                </Col>
                            </Row>
                        </Container>
                    </div>

                    <div className="header py-7 py-lg-8 bg-gradient-info">
                        <Container>
                            <div className="header-body text-left mb-5 pb-3">
                                <Row className="justify-content-center">
                                    <Col lg="6" md="6">

                                    </Col>
                                    <Col lg="6" md="6">
                                        <h1 className="text-white">Welcome to Atheia!</h1>
                                        <p className="text-lead text-light">
                                            We’re a platform that allows you to have a centralised way of saving your data and notes about the players you’re analysing.
Save and access your information anytime, anywhere.</p>
                                    </Col>
                                </Row>
                            </div>
                        </Container>

                    </div>
                    {/* Page content */}
                    <Container className="mt--8 pb-5">
                        <Row className="justify-content-center">
                            <Switch>
                                {utils.mapRoutes(publicRoutes, "/site")}
                            </Switch>
                        </Row>
                    </Container>
                </div>
                <SiteFooter />
            </React.Fragment>
        )

    }
}

export default Site;