import React from 'react'
import { Formik } from 'formik';
import PropTypes from 'prop-types'
import * as api from '../../api/api';

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Modal,
    Row,
    Col
} from "reactstrap";


class CreateOutcast extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            baseurl: '',
        }


        this.closeModal = this.closeModal.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleSubmit(values, { setSubmitting }) {
        api.addOrganizationOutcast(this.props.match.params.organization_id, {
            baseurl: values.baseurl
        }).then(data => {
            this.props.updateOutcasts();
            this.props.closeModal();
        })

    }

    closeModal() {
        this.props.closeModal();
    }

    render() {
        return (
            <React.Fragment>
                <div className="modal-header">
                    <h6 className="modal-title" id="modal-title-default">
                        Create/Edit News Group
                    </h6>
                </div>
                <Formik enableReinitialize initialValues={{ baseurl: this.state.baseurl }}
                    onSubmit={this.handleSubmit}>
                    {({ values, handleSubmit, handleChange, handleBlur }) => (<form onSubmit={handleSubmit}>
                        <div className="modal-body">
                            <Row>
                                <Col lg="3">
                                    <label htmlFor="name">Baseurl</label>
                                </Col>
                                <Col lg="9">
                                    <input id="baseurl" name="baseurl" type="text" value={values.baseurl} onChange={handleChange}
                                        onBlur={handleBlur} />
                                </Col>
                            </Row>


                        </div>
                        <div className="modal-footer">
                            <Button color="primary" type="submit">
                                Save
                            </Button>
                            <Button type="button" onClick={this.closeModal}>
                                Cancel
                            </Button>
                        </div>
                    </form>)}
                </Formik>
            </React.Fragment >
        )
    }

}

CreateOutcast.propTypes = {
    updateOutcasts: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired
}

export default CreateOutcast;