import React from 'react'
import { Formik } from 'formik';
import PropTypes from 'prop-types'
import * as api from '../../api/api';
const enums = require('../../../common/enums');


import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Modal,
    Row,
    Col
} from "reactstrap";


class CreateOrganization extends React.Component {


    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }


    closeModal() {
        this.props.closeModal();
    }

    handleSubmit(values, { setSubmitting }) {
        api.createOrganization({
            name: values.name,
            type: values.type,
            federation: values.federation
        }).then(data => {
            this.props.updateOrganizations();
            this.props.closeModal();
        })
    }

    render() {
        return (<React.Fragment>
            <div className="modal-header">
                <h6 className="modal-title" id="modal-title-default">
                    Create Organization
        </h6>
            </div>
            <Formik initialValues={{ name: '', type: 'Club', federation: '' }}
                onSubmit={this.handleSubmit}>
                {({ values, handleSubmit, handleChange, handleBlur }) => (<form onSubmit={handleSubmit}>
                    <div className="modal-body">

                        <div>
                            <label htmlFor="name">Name</label>
                            <input id="name" name="name" type="text" value={values.name} onChange={handleChange}
                                onBlur={handleBlur} />
                        </div>
                        <div>
                            <label htmlFor="type">Type</label>
                            <input id="type" name="type" checked={values.type === "Club"}
                                onChange={() => setFieldValue("type", "Club")}
                                 type="radio" value='Club' onChange={handleChange}
                                onBlur={handleBlur} /> Club
                            <input id="type" name="type" checked={values.type === "Agency"}
                                onChange={() => setFieldValue("type", "Agency")}
                                type="radio" value='Agency' onChange={handleChange}
                                onBlur={handleBlur} /> Agency
                        </div>
                        <div>
                        <label htmlFor="federation">Federation</label>
                            <select
                                name="federation"
                                value={values.federation}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            >
                                <option value="" label=""></option>
                                {enums.federations.map((fed,i)=>{
                                    return (<option key={i} value={fed} label={fed} />)
                                })}
                                

                                
                            </select>
                        </div>
                        <span className="input-group-btn">

                        </span>

                    </div>
                    <div className="modal-footer">
                        <Button color="primary" type="submit">
                            Create Organization
                </Button>
                        <Button type="button" onClick={this.closeModal}>
                            Cancel
                </Button>
                    </div>
                </form>)}
            </Formik></React.Fragment >);
    }

}

CreateOrganization.propTypes = {
    updateOrganizations: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired
}

export default CreateOrganization;