import React from "react";

import { Link } from "react-router-dom";
import PropTypes from 'prop-types'


import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Media
} from "reactstrap";

class SiteNavBar extends React.Component {


  constructor(props) {
    super(props)
    this.logout = this.logout.bind(this)
    
  }

  logout(e) {
    e.preventDefault()
  }


  render() {
    return (<Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
      <Container fluid>
        <Link
          className="h4 mb-0  text-uppercase d-none d-lg-inline-block"
          to="/"
        >
          {this.props.brandText}
        </Link>
       
      </Container>
    </Navbar>)
  }
}

SiteNavBar.propTypes = {
}

export default SiteNavBar