import React from 'react'
import { Route, withRouter } from 'react-router-dom';
import { Formik } from 'formik';
import * as api from '../api/api';
import LoginForm from './LoginForm.jsx'

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Container,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Modal,
    Row,
    Col
} from "reactstrap";

import { toast } from 'react-toastify';



class Register extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            name: '',
            email: '',
            userName: '',
            statesValue: null,
            forbiddenWords: ['password', 'user', 'username']
        }
        this.handleSubmit = this.handleSubmit.bind(this)
    }


    componentDidMount() { }



    handleSubmit(values, { setSubmitting }) {
        api.createUser({
            name: values.name,
            email: values.email,
            password: values.password,
            coupon: values.coupon
        }).then(() => toast("User created. You can now log in."))
            .catch(err => {
                switch (err.response.status) {
                    case 400:
                        toast.error(err.response.data.message.join('\n'))
                        break;
                    case 412:
                        toast.error(err.response.data.message)
                        break;
                    case 409:
                        toast.error("This e-mail is registered already.")
                        break;
                    default:
                        toast.error("Ooops. Something went wrong!")

                }

            })

    }

    render() {
        return (
            <React.Fragment>
                <Col lg="10" md="11">
                    <Card className="bg-secondary shadow border-0">
                        <Row>
                            <Col lg="6" md="6">
                                <CardHeader className="bg-transparent px-lg-5 py-lg-5">
                                    <div className="text-muted text-left mt-2 mb-4">
                                        <h3 className="text-white">Sign In</h3>
                                    </div>
                                    <div className="text-left">
                                        <LoginForm />
                                    </div>
                                </CardHeader>
                            </Col>
                            <Col lg="6" md="6">
                                <CardBody className="px-lg-5 py-lg-5">
                                    <div className="text-left text-muted mt-2 mb-4">
                                        <h3 className="text-white">Or sign up</h3>
                                    </div>
                                    <div className="registerForm">
                                        <Formik initialValues={{ email: '', name: '', password: '', coupon: '' }}
                                            onSubmit={this.handleSubmit}>
                                            {({ values, handleSubmit, handleChange, handleBlur }) => (<form onSubmit={handleSubmit}>
                                                <Container>
                                                    <Row className="mb-2">
                                                        <Col className="text-left text-gray md-3 lg-4"><label htmlFor="email">E-Mail</label></Col>
                                                        <Col className="text-left md-9 lg-8"><input id="email" name="email" type="text" value={values.email} onChange={handleChange}
                                                            onBlur={handleBlur} /></Col>
                                                    </Row>
                                                    <Row className="mb-2">
                                                        <Col className="text-left text-gray md-3 lg-4"> <label htmlFor="name">Name</label></Col>
                                                        <Col className="text-left md-9 lg-8"> <input id="name" name="name" type="text" value={values.name} onChange={handleChange}
                                                            onBlur={handleBlur} /></Col>
                                                    </Row>
                                                    <Row className="mb-2">
                                                        <Col className="text-left text-gray md-3 lg-4"><label htmlFor="password">Password</label></Col>
                                                        <Col className="text-left md-9 lg-8"><input id="password" name="password" type="password" value={values.password} onChange={handleChange}
                                                            onBlur={handleBlur} /></Col>
                                                    </Row>
                                                    <Row className="mb-2">
                                                        <Col className="text-left text-gray md-3 lg-4"> <label htmlFor="coupon">Coupon</label></Col>
                                                        <Col className="text-left md-9 lg-8"><input id="coupon" name="coupon" type="text" value={values.coupon} onChange={handleChange}
                                                            onBlur={handleBlur} /></Col>
                                                    </Row>
                                                </Container>
                                                <div className=" text-center">
                                                    <span className="input-group-btn">
                                                        <button type="submit" className="btn btn-info mt-3">
                                                            Create account
                                                    </button>
                                                    </span>
                                                </div>
                                            </form>)}
                                        </Formik>
                                    </div>
                                </CardBody>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </React.Fragment>
        );
    }
}

export default withRouter(Register);
