import axios from 'axios'

export const createUser = user => {
  return axios.post('/api/users', user).then(resp => resp.data)
}

export const validateToken = () => {
  return axios.get('/api/validatetoken').then(resp => resp.data)
}

export const login = payload => {
  return axios.post('/api/login', payload).then(resp => resp.data)
}

export const fetchPlayerLists = () => {
  return axios.get('/api/playerlists').then(resp => resp.data)
}

export const createPlayerList = playerlist => {
  return axios.post('/api/playerlists', playerlist).then(resp => resp.data)
}

export const fetchUsers = () => {
  return axios.get('/api/users').then(resp => resp.data)
}

export const changePassword = changePasswordRequest => {
  return axios.patch('/api/user/changepassword', changePasswordRequest).then(resp => resp.data)
}

export const getInvitations = () => {
  return axios.get('/api/user/invitations').then(resp => resp.data)
}