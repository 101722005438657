import React from 'react';
import { render } from 'react-dom';

import ReactDOM from 'react-dom';

//import App from './components/App.jsx';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

//import adr from "./assets/scss/argon-dashboard-react.scss";
//import ReactToastify from 'react-toastify/dist/ReactToastify.css';



import styles from './scss/application.scss';

// import AuthLayout from "./components/layouts/Auth.jsx";
// import MainLayout from "./components/layouts/Main.jsx";
// import SiteLayout from "./components/layouts/Site.jsx";

import { toast } from 'react-toastify';

import App from './components/App.jsx'
toast.configure()


// render(
//   <BrowserRouter>
//     <Switch>
//       <Route path="/auth" render={props => <AuthLayout {...props} />} />
//       <Route path="/app" render={props => <MainLayout {...props} />} />
//       <Route path="/site" render={props => <SiteLayout {...props} />} />
//       <Redirect from="/" to="/app" />
//     </Switch>
//   </BrowserRouter>,
//   document.getElementById('root')
// );

ReactDOM.hydrate(
    <React.Fragment>
        <BrowserRouter><App /></BrowserRouter>
    </React.Fragment>, document.getElementById('root'));
