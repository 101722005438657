import React from 'react'

import { Formik } from 'formik';


import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Row,
    Col} from "reactstrap";


import { toast } from 'react-toastify';


import * as api from '../../api/api';


class ChangePasswordCard extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            oldPassword: '',
            password: '',
            confirmPassword: ''
        }
        this.handleSubmit = this.handleSubmit.bind(this)

    }

    handleSubmit(values) {
        api.changePassword({
            oldPassword: values.oldPassword,
            newPassword: values.password,
            confirmPassword: values.confirmPassword
        })
        .then(data => {
            toast.success(data.message)
        })
        .catch(err => {
            toast.error(err.response.data.error)
        })
        

    }

    render() {
        return (
            <React.Fragment>
                <Card className="card-profile shadow">
                    <Row className="justify-content-center">
                        <Col className="order-lg-2" lg="3">
                            <div className="card-profile-image">
                                <a href="#pablo" onClick={e => e.preventDefault()}>

                                </a>
                            </div>
                        </Col>
                    </Row>
                    <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                        <div className="d-flex justify-content-between">

                            <h1>Change Password</h1>
                        </div>
                    </CardHeader>
                    <CardBody className="pt-0 pt-md-4">

                        <Formik initialValues={{
                            oldPassword: this.state.oldPassword,
                            password: this.state.password,
                            confirmPassword: this.state.confirmPassword
                        }}
                            onSubmit={this.handleSubmit} enableReinitialize={true}>
                            {({ values, handleSubmit, handleChange, handleBlur }) => (<form onSubmit={handleSubmit} >
                                <Row>
                                    <Col lg="3">
                                        <label htmlFor="oldPassword">Current Password</label>
                                        <input id="oldPassword" name="oldPassword"  type="password" value={values.oldPassword} required onChange={handleChange}
                                            onBlur={handleBlur} />
                                    </Col>
                                    <Col lg="3">
                                        <label htmlFor="password">New Password</label>
                                        <input id="password" name="password"  type="password" value={values.password} required onChange={handleChange}
                                            onBlur={handleBlur} />
                                    </Col>
                                    <Col lg="3">
                                        <label htmlFor="confirmPassword">Confirm New Password</label>
                                        <input id="confirmPassword" name="confirmPassword"  type="password" value={values.confirmPassword} required onChange={handleChange}
                                            onBlur={handleBlur} />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <span className="input-group-btn">
                                        <Button color="primary" type="submit">
                                            Change Password
                                        </Button>
                                        </span>
                                    </Col>
                                </Row>
                            </form>)}
                        </Formik>
                    </CardBody>
                </Card>
            </React.Fragment>
        )
    }
}

export default ChangePasswordCard