import React from 'react'
import { Route, withRouter } from 'react-router-dom';

import { Formik } from 'formik';
import { PropTypes } from "prop-types";

import MainHeader from '../headers/MainHeader.jsx'
import ChangePasswordCard from './ChangePasswordCard.jsx'


import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Container,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Modal,
    Row,
    Col,
    Table
} from "reactstrap";

class Settings extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            name: ''
        }



        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleSubmit(values, { setSubmitting }) {

    }

    render() {
        return (
            <React.Fragment>
                <MainHeader title="Settings" />
                <Container className="mt--7" fluid>
                    <Row>
                        <Col className="order-xl-2 mb-5 mb-xl-0" xl="12">
                            <Card className="card-profile shadow">
                                <Row className="justify-content-center">
                                    <Col className="order-lg-2" lg="3">
                                        Teste
                                    </Col>
                                </Row>
                                <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                                    <div className="d-flex justify-content-between">

                                        <Button
                                            className="float-right"
                                            color="default"
                                            href="#pablo"
                                            onClick={e => this.props.switchEditMode(false)}
                                            size="sm"
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                </CardHeader>
                                <CardBody className="pt-0 pt-md-4">
                                    <Row>
                                        <Formik initialValues={{
                                            name: this.state.name
                                        }}
                                            onSubmit={this.handleSubmit} enableReinitialize={true}>
                                            {({ values, handleSubmit, handleChange, handleBlur }) => (<form onSubmit={handleSubmit} >
                                                <div className="text-center">
                                                    <div>
                                                        <label htmlFor="name">Name</label>
                                                        <input id="name" name="name" type="text" value={values.name} required onChange={handleChange}
                                                            onBlur={handleBlur} />
                                                    </div>
                                                </div>
                                            </form>)}
                                        </Formik>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="order-xl-2 mb-5 mb-xl-0" xl="12">
                            <ChangePasswordCard />
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
        )
    }
}

export default withRouter(Settings)