import React from 'react'
import { Formik } from 'formik';
import { Route, withRouter } from 'react-router-dom';

import * as api from '../api/api';

import { toast } from 'react-toastify';

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Container,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Modal,
    Row,
    Col
} from "reactstrap";

class LoginForm extends React.Component {

    constructor(props) {
        super(props)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    componentDidMount() { }

    handleSubmit(values, { setSubmitting }) {
        api.login({
            email: values.email,
            password: values.password
        }).then(data => {
            localStorage.setItem('jwt-token', data.token)
            localStorage.setItem('user', JSON.stringify(data.user))
            this.props.history.push(`/app`)
        }).catch(() => toast.error("Ooops. Something went wrong!"))
    }

    render() {
        return (<div>
            <Formik initialValues={{ email: '', password: '' }}
                onSubmit={this.handleSubmit}>
                {({ values, handleSubmit, handleChange, handleBlur }) => (<form onSubmit={handleSubmit}>
                    <Container>

                        <Row className="mb-2">

                            <Col className="text-left text-gray md-3 lg-4"><label htmlFor="email">E-Mail</label></Col>
                            <Col><input id="email" name="email" type="text" value={values.email} onChange={handleChange}
                                onBlur={handleBlur} /></Col>

                        </Row>

                        <Row className="mb-2">

                            <Col className="text-left text-gray md-3 lg-4">
                                <label htmlFor="password">Password</label></Col>
                            <Col><input id="password" name="password" type="password" value={values.password} onChange={handleChange}
                                onBlur={handleBlur} /></Col>

                        </Row>

                    </Container>
                    <div className=" text-center">
                        <span className="input-group-btn">
                            <button type="submit" className="btn btn-info mt-3">
                                Log in
                         </button>
                        </span>
                    </div>
                </form>)}
            </Formik>
        </div>);
    }
}

export default withRouter(LoginForm);