import Register from './components/Register.jsx'


var publicRoutes = [
    {
        path: "/",
        name: "Register",
        icon: "",
        component: Register,
        layout: "/site",
        exact: true
    },
]


export default publicRoutes