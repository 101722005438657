import React from 'react'
import { Route, withRouter } from 'react-router-dom';
import { Formik } from 'formik';
import * as api from '../../api/api';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import MainHeader from '../headers/MainHeader.jsx'

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Container,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Modal,
    Row,
    Col,
    Table
} from "reactstrap";

class UserList extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            users: [],
        }

        this.updateUsers = this.updateUsers.bind(this)
    }

    componentDidMount() {
        this.updateUsers()
    }

    updateUsers() {
        api.fetchUsers().then(users => {
            this.setState({
                users: users.data
            })
        })
    }

    render() {
        return (
            <React.Fragment>
                <MainHeader title="Users" />
                <Container className="mt--7" fluid>
                    <Row>

                    </Row>
                    <Row>
                        <Col md="8">
                            <Table className="align-items-center table-flush" responsive>
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col">Name</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Roles</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.users.map(u => {
                                        return (
                                            <tr key={u._id}>
                                                <th scope="row"><div onClick={() => this.goToPlayer(u._id)}>{u.name}</div></th>
                                                <td>{u.email}</td>
                                                <td>{u.roles.join(',')}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
        )
    }
}

export default withRouter(UserList);