import React from 'react'
import { Route, withRouter } from 'react-router-dom';

import * as api from '../../api/api';

import InviteMember from './InviteMember.jsx';



import MainHeader from '../headers/MainHeader.jsx'

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Modal,
  Row,
  Col,
  Table
} from "reactstrap";

class Members extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      organization: {},
      members: [],
      inviteMemberModalOpen: false
    }
    this.toggleModal = this.toggleModal.bind(this)
    this.getMembers = this.getMembers.bind(this)
    this.notifyResult = this.notifyResult.bind(this)
    this.fetchOrganization = this.fetchOrganization.bind(this)
  }

  componentDidMount() {
    this.getMembers()
    this.fetchOrganization()
  }

  toggleModal(state) {
    this.setState({
      [state]: !this.state[state]
    });
  }

  notifyResult() {

  }

  fetchOrganization() {
    api.getOrganization(this.props.match.params.organization_id).then(data => {
        this.setState({
            organization: data.data
        })
    })
}


  getMembers() {
    api.getOrganizationMembers(this.props.match.params.organization_id).then(data => this.setState({
      members: data.data
    }))
  }


  render() {
    return (
      <React.Fragment>
        <MainHeader title={<><a>Organization {this.state.organization.name}</a> - Members</> }/>
        <Container className="mt--7" fluid>
          <Row>
            <div className=" col">
              <Button
                block
                className="mb-3"
                color="primary"
                type="button"
                onClick={() => this.toggleModal("inviteMemberModalOpen")}
              >
                Invite Member
                            </Button>
              <Modal
                className="modal-dialog-centered"
                isOpen={this.state.inviteMemberModalOpen}
                toggle={() => this.toggleModal("inviteMemberModalOpen")}
                
              >
                <InviteMember
                  notifyResult={this.notifyResult}
                  closeModal={() => this.toggleModal("inviteMemberModalOpen")}
                  {...this.props}
                />
              </Modal>
            </div>
          </Row>
          <Row>
            <Col md="8">
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Type</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.members.map(u => {
                    return (
                      <tr key={u._id}>
                        <th scope="row"><div onClick={() => this.goToOrganization(u._id)}>{u.member.name}</div></th>
                        <td>{u.type}</td>

                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    )
  }
}

export default withRouter(Members);