import React from 'react'
import { Formik } from 'formik';
import * as api from '../../api/api';


class Auth extends React.Component {

    constructor(props){
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    componentDidMount() { }

    handleSubmit(values, { setSubmitting }) {
        api.login({
            email: values.email,
            password: values.password
        }).then(data => {
            localStorage.setItem('jwt-token', data.token)
            localStorage.setItem('user', JSON.stringify(data.user))
            this.props.history.push(`/app`)

        })
    }

    render() {
        return (<div>
            <Formik initialValues={{ email: '', password: '' }}
                    onSubmit={this.handleSubmit}>
                    {({values, handleSubmit, handleChange, handleBlur}) => (<form onSubmit={handleSubmit}>
                        <div>
                            <label htmlFor="email">E-Mail</label>
                            <input id="email" name="email" type="text" value={values.email} onChange={handleChange}
            onBlur={handleBlur} />
                        </div>
                        <div>
                            <label htmlFor="password">Password</label>
                            <input id="password" name="password" type="password" value={values.password} onChange={handleChange}
            onBlur={handleBlur} />
                        </div>
                        <span className="input-group-btn">
                            <button type="submit" className="btn btn-info">
                                Login
            </button>
                        </span>
                    </form>)}
                </Formik>
        </div>);
    }
}

export default Auth;