import React from "react";

import { Link } from "react-router-dom";
import PropTypes from 'prop-types'


import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Media
} from "reactstrap";
import { Zoom } from "react-toastify";

class AdminNavbar extends React.Component {


  constructor(props) {
    super(props)
    this.logout = this.logout.bind(this)

  }

  logout(e) {
    e.preventDefault()
    this.props.logout()
  }



  render() {
    return (<Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
      <Container fluid>
        <Link
          className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
          to="/"
        >
          {this.props.brandText}
        </Link>
        {/* <Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
              <FormGroup className="mb-0">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fas fa-search" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder="Search" type="text" />
                </InputGroup>
              </FormGroup>
            </Form> */}
        <Nav className="align-items-center d-none d-md-flex" navbar>
          <UncontrolledDropdown nav>
            <DropdownToggle className="pr-0" nav>
              <Media className="align-items-center">
                <span className="avatar avatar-sm rounded-circle">
                <img src="/img/soccer-ball.svg" alt="Menu" width="50%" />
                 {/*  <img
                    alt="..."
                  //src={require("assets/img/theme/team-4-800x800.jpg")}
                  /> */}
                </span>
                <Media className="ml-2 d-none d-lg-block">
                  <span className="mb-0 text-sm font-weight-bold">
                    {typeof window !== 'undefined' && localStorage.getItem("user") != null ? JSON.parse(localStorage.getItem("user")).name : ""}
                  </span>
                </Media>
              </Media>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow" right>
              <DropdownItem className="noti-title" header tag="div">
                <h6 className="text-overflow m-0">Welcome! {this.props.notifications.length}</h6>
              </DropdownItem>
              <DropdownItem to="/admin/user-profile" tag={Link}>
                <i className="ni ni-single-02" />
                <span>My profile</span>
              </DropdownItem>
              <DropdownItem to="/app/user/settings" tag={Link}>
                <i className="ni ni-settings-gear-65" />
                <span>Settings</span>
              </DropdownItem>
              <DropdownItem to="/app/user/invitations" tag={Link}>
                <i className="ni ni-settings-gear-65" />
                <span>Invitations [{this.props.notifications.filter(x => x.type=='Invitation').length}]</span>
              </DropdownItem>
              <DropdownItem to="/app/user/activity" tag={Link}>
                <i className="ni ni-calendar-grid-58" />
                <span>Activity</span>
              </DropdownItem>
              <DropdownItem to="/admin/user-profile" tag={Link}>
                <i className="ni ni-support-16" />
                <span>Support</span>
              </DropdownItem>
              <DropdownItem divider />
              <DropdownItem href="#pablo" onClick={this.logout}>
                <i className="ni ni-user-run" />
                <span>Logout</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </Container>
    </Navbar>)
  }
}

AdminNavbar.propTypes = {
  logout: PropTypes.func.isRequired,
  notifications: PropTypes.array.isRequired,
}

export default AdminNavbar