import axios from 'axios'

export const fetchPlayersFromList = playerlistId => {
    return axios.get(`/api/playerlists/${playerlistId}`).then(resp => resp.data)
}

export const fetchAllPlayers = playerlistId => {
    return axios.get('/api/players').then(resp => resp.data)
}

export const createPlayer = (player, playerListId) => {
    return axios.post(`/api/playerlists/${playerListId}`, player).then(resp => resp.data)
}

export const addPlayerFromTM = (player, playerListId) => {
    return axios.post(`/api/playerlists/${playerListId}/fromtm`, player).then(resp => resp.data)
}

export const updatePlayer = (player, playerid) => {
    return axios.put(`/api/players/${playerid}`, player).then(resp => resp.data)
}

export const updatePlayerVisibility = (visibility, playerid) => {
    return axios.put(`/api/players/${playerid}/visibility`, visibility).then(resp => resp.data)
}

export const fetchPlayer = playerId => {
    return axios.get(`/api/players/${playerId}`).then(resp => resp.data)
}

export const fetchPlayersSharedWithMe = () => {
    return axios.get('/api/playerssharedwithme').then(resp => resp.data)
}

export const addAnalysisToPlayer = (playerId, analysis) => {
    return axios.post(`/api/players/${playerId}/analysis`, analysis).then(resp => resp.data)
}

export const getAnalysis = (playerId, analysisId) => {
    return axios.get(`/api/players/${playerId}/analysis/${analysisId}`).then(resp => resp.data)
}

export const updateAnalysis = (playerId, analysisId, analysis) => {
    return axios.put(`/api/players/${playerId}/analysis/${analysisId}`, analysis).then(resp => resp.data)
}

export const deleteAnalysis = (playerId, analysisId) => {
    return axios.delete(`/api/players/${playerId}/analysis/${analysisId}`).then(resp => resp.data)
}

export const searchMetaPlayer = (query) => {
    return axios.get(`/api/eplayers/${query}`).then(resp => resp.data)
}

export const getMetaPlayer = (external_player_id) => {
    return axios.get(`/api/eplayers/findById/${external_player_id}`).then(resp => resp.data)
}

export const getMetaPlayerPublicAnalyses = (external_player_id) => {
    return axios.get(`/api/players/${external_player_id}/publicanalyses`).then(resp => resp.data)
}

export const getHotProspects = () => {
    return axios.get('/api/hotprospects').then(resp => resp.data)
}

export const getPublicPlayers = (query) => {
    return axios.post('/api/publicplayers',query).then(resp => resp.data)
}