import React from 'react'
import { Route, withRouter } from 'react-router-dom';

import * as api from '../../api/api';


import MainHeader from '../headers/MainHeader.jsx'

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Container,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Modal,
    Row,
    Col,
    Table
} from "reactstrap";


class Organization extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            organization: {}
        }

        this.fetchOrganization = this.fetchOrganization.bind(this)
        this.goToMembers = this.goToMembers.bind(this)
        this.goToNewsTracker = this.goToNewsTracker.bind(this)

    }

    componentDidMount() {
        this.fetchOrganization();
    }


    fetchOrganization() {
        api.getOrganization(this.props.match.params.organization_id).then(data => {
            this.setState({
                organization: data.data
            })
        })
    }

    goToMembers() {
        this.props.history.push(`/app/organizations/${this.props.match.params.organization_id}/members`)
    }

    goToPlayers() {
        this.props.history.push(`/app/organizations/${this.props.match.params.organization_id}/players`)
    }


    goToNewsTracker() {
        this.props.history.push(`/app/organizations/${this.props.match.params.organization_id}/newstracker`)
    }



    render() {
        return (
            <React.Fragment>
                <MainHeader title={`Organization ${this.state.organization.name}`} />
                <Container className="mt--7" fluid>
                    <Row>
                        <Col className="order-xl-2 mb-5 mb-xl-0" xl="3">
                            <a onClick={this.goToMembers}>Members</a>
                        </Col>
                        <Col className="order-xl-2 mb-5 mb-xl-0" xl="3">
                            <a onClick={this.goToPlayers}>Players</a>
                        </Col>
                        <Col className="order-xl-2 mb-5 mb-xl-0" xl="3">
                            <a onClick={this.goToNewsTracker}>News Tracker</a>
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
        )
    }
}


export default withRouter(Organization);