exports.user = () => {
    return JSON.parse(localStorage.getItem('user'))
}


exports.isUserAdmin = () => {
    return (
        typeof window !== 'undefined' &&
        'jwt-token' in localStorage &&
        'user' in localStorage &&
        module.exports.user().roles != undefined &&
        module.exports.user().roles.indexOf('Admin') > -1
    )
}

exports.isUserOrgAdmin = () => {
    return (
        typeof window !== 'undefined' &&
        'jwt-token' in localStorage &&
        'user' in localStorage &&
        module.exports.user().roles != undefined &&
        module.exports.user().roles.indexOf('OrgAdmin') > -1
    )
}
