import React from 'react'

import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Navbar,
  Nav,
  NavItem,
  Container,
  NavLink,
  Media,
  Collapse
} from 'reactstrap';

import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";

const user = require('../core/user.js')


class NavigationBar extends React.Component {

  constructor(props) {
    super(props)
    this.navigateTo = this.navigateTo.bind(this)
    this.state = {
      collapseOpen: false,
    };

    this.toggleCollapse = this.toggleCollapse.bind(this)
    this.closeCollapse = this.closeCollapse.bind(this)
  }


  navigateTo(route) {
    this.props.history.push(route);
    this.closeCollapse();

  }

  toggleCollapse() {

    this.setState({
      collapseOpen: !this.state.collapseOpen
    });
  };
  // closes the collapse
  closeCollapse() {
    this.setState({
      collapseOpen: false
    });
  };

  render() {
    return (
      <Navbar
        className="navbar-vertical fixed-left navbar-light bg-white"
        expand="md"
        id="sidenav-main"
      >
        <Container fluid>
          <button
            className="navbar-toggler"
            type="button"
            onClick={this.toggleCollapse}
          >
            <span className="navbar-toggler-icon" />
          </button>
          <Nav className="align-items-center d-md-none">
            <UncontrolledDropdown nav>
              <DropdownToggle nav className="nav-link-icon">
                <i className="ni ni-bell-55" />
              </DropdownToggle>
              <DropdownMenu
                aria-labelledby="navbar-default_dropdown_1"
                className="dropdown-menu-arrow"
                right
              >
                <DropdownItem>Action</DropdownItem>
                <DropdownItem>Another action</DropdownItem>
                <DropdownItem divider />
                <DropdownItem>Something else here</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown nav>
              <DropdownToggle nav>
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle">


                    <img
                      alt="..."
                    //src={require("assets/img/theme/team-1-800x800.jpg")}
                    />
                  </span>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Welcome! {this.props.notifications.length}</h6>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-single-02" />
                  <span>My profile</span>
                </DropdownItem>
                <DropdownItem to="/app/user/settings" tag={Link}>
                  <i className="ni ni-settings-gear-65" />
                  <span>Settings</span>
                </DropdownItem>
                <DropdownItem to="/app/user/invitations" tag={Link}>
                  <i className="ni ni-settings-gear-65" />
                  <span>Invitations [{this.props.notifications.filter(x => x.type == 'Invitation').length}]</span>
                </DropdownItem>
                <DropdownItem to="/app/user/activity" tag={Link}>
                  <i className="ni ni-calendar-grid-58" />
                  <span>Activity</span>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-support-16" />
                  <span>Support</span>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem href="#pablo" onClick={e => e.preventDefault()}>
                  <i className="ni ni-user-run" />
                  <span>Logout</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
          <Collapse navbar isOpen={this.state.collapseOpen}>
            <Nav className="mb-md-3" navbar>
              <NavItem>
                <NavLink onClick={() => this.navigateTo('/app')}>
                  <i className="ni ni-ui-04" />
                    Home
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink>
                  <i className="ni ni-ui-04" />
                  <hr />
                </NavLink>
              </NavItem>
              {user.isUserOrgAdmin() ?
                <NavItem>
                  <NavLink onClick={() => this.navigateTo('/app/organizations')}>
                    <i className="ni ni-ui-04" />
                  Organizations
                </NavLink>
                </NavItem>
                :
                <React.Fragment>
                </React.Fragment>}
              <NavItem>
                <NavLink>
                  <i className="ni ni-ui-04" />
                  <hr />
                </NavLink>
              </NavItem>
              

              {user.isUserAdmin() ?
                <NavItem>
                  <NavLink onClick={() => this.navigateTo('/app/users')}>
                    <i className="ni ni-ui-04" />
                    Users
                  </NavLink>
                </NavItem>
                :
                <React.Fragment>
                </React.Fragment>}
            </Nav>
          </Collapse>
        </Container>
      </Navbar>)

  }
}

NavigationBar.propTypes = {
  notifications: PropTypes.array.isRequired,
}


export default NavigationBar;