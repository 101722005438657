import React from "react";
import { Route, withRouter } from "react-router-dom";
import { Formik } from "formik";
import * as api from "../../api/api";
import MainHeader from "../headers/MainHeader.jsx";


import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  FormGroup,
  Form,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Modal,
  Row,
  Col,
  Table,
} from "reactstrap";

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.updatePlayers = this.updatePlayers.bind(this);

    this.state = {
      scountingPlayers: 0,
    };
  }

  updatePlayers() {
    return api.fetchAllPlayers(this.props.match.params.playerlist_id);
  }

  render() {
    return (
      <React.Fragment>
        <MainHeader title="Welcome" />
        <Container fluid>
          <Row>
            <Col className="order-xl-1" xl="3">
              {this.state.scountingPlayers} being scout
            </Col>
            <Col className="order-xl-1" xl="3">
              X
            </Col>
            <Col className="order-xl-1" xl="3">
              X
            </Col>
            <Col className="order-xl-1" xl="3">
              X
            </Col>
          </Row>
        </Container>
        <Container fluid>
          <Row>
            <Col className="order-xl-1" xl="8">
              
            </Col>
            <Col className="order-xl-1" xl="4">
              
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default withRouter(Home);
