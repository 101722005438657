import React from 'react'
import { Switch, withRouter } from 'react-router-dom'

import NavigationBar from '../NavigationBar.jsx'
import AdminNavbar from '../navbars/AdminNavbar.jsx'

import routes from "../../routes.js";

import * as api from '../../api/api';

import * as utils from '../../../common/utils.js'


class Main extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            user: null,
            token: null,
            notifications: []
        }


        this.logout = this.logout.bind(this)
        this.validateToken = this.validateToken.bind(this)
    }

    componentDidMount() {
        if (typeof window !== undefined) {
            if (localStorage.getItem('jwt-token') === undefined
                || localStorage.getItem('jwt-token') === null
                || localStorage.getItem('jwt-token') === "") {
                this.props.history.push(`/site`)
            } else {
                this.setState(
                    {
                        user: JSON.parse(localStorage.getItem('user')),
                        token: localStorage.getItem('jwt-token')
                    }, () => {
                        this.validateToken()
                    })

            }
        }
    }

    componentDidUpdate(e) {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        //this.refs.mainContent.scrollTop = 0;
    }

   

    validateToken() {
        if (this.state.token !== null) {
            api
                .validateToken()
                .then((data) => {
                    this.setState({
                        notifications: data.notifications
                    })

                })
                .catch(err => {
                    this.logout()
                })
        }
        setTimeout(this.validateToken.bind(this), 5 * 60 * 1000)
    }

    logout() {
        localStorage.clear();
        this.props.history.push(`/site`)
    }

    render() {
        return (
            <React.Fragment>
                <NavigationBar notifications={this.state.notifications}       {...this.props}
                />
                <div className="main-content">
                    <AdminNavbar logout={this.logout} notifications={this.state.notifications} {...this.props} />
                    <Switch>
                        {utils.mapRoutes(routes,"/app")}
                    </Switch>
                </div>
            </React.Fragment>
        );
    }
}
export default withRouter(Main);