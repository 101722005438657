import React from 'react'

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Container,
    Col,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Modal,
    Nav,
    NavItem,
    NavLink,
    Row

} from "reactstrap";

class SiteFooter extends React.Component {
    render() {
        return (
            <React.Fragment>
                <footer className="py-5">
                    <Container>
                        <Row className="align-items-center justify-content-xl-between">
                            <Col xl="6">
                                <div className="copyright text-center text-xl-left text-muted">
                                    Atheia © {(new Date()).getFullYear() + " "}
                                    <a
                                        className="font-weight-bold ml-1"
                                        href=""
                                        target="_blank"
                                    >

                                    </a>
                                </div>
                            </Col>
                            <Col xl="6">
                                <Nav className="nav-footer justify-content-center justify-content-xl-end">

                                    <NavItem>
                                        <NavLink href="/site/about-us">About Us</NavLink>
                                    </NavItem>

                                    <NavItem>
                                        <NavLink href="/site/contacts">Contacts</NavLink>
                                    </NavItem>
                                </Nav>
                            </Col>
                        </Row>
                    </Container>
                </footer>
            </React.Fragment>
        )
    }
}

export default SiteFooter;