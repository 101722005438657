import React from 'react'
import { Route, withRouter } from 'react-router-dom';

import { confirmAlert } from 'react-confirm-alert';

import { toast } from 'react-toastify';



import * as api from '../../api/api';


import {
    Button,
    Card,
    CardHeader,
    CardBody,
    Container,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Modal,
    Row,
    Col,
    Table
} from "reactstrap";

import MainHeader from '../headers/MainHeader.jsx'
import CreateOutcast from './CreateOutcast.jsx';


class Outcasts extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            outcasts: [],
            createNewsGroupModalOpen: false,
        }
        this.updateOutcasts = this.updateOutcasts.bind(this)
        this.toggleModal = this.toggleModal.bind(this)
    }

    componentDidMount() {
        this.updateOutcasts();
    }

    updateOutcasts() {
        api.fetchOrganizationOutcasts(this.props.match.params.organization_id).then(outcasts => {
            this.setState({
                outcasts: outcasts
            })
        })
    }

    toggleModal(state) {
        this.setState({
            [state]: !this.state[state]
        });
    }

    deleteOutcastConfirm(outcastId) {
        confirmAlert({
            title: 'Confirm deletion',
            message: 'Are you sure you want to remove this site?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => this.deleteOutcast(outcastId)
                },
                {
                    label: 'No'
                }
            ]
        });
    }

    deleteOutcast(outcastId) {
        api.deleteOrganizationOutcast(this.props.match.params.organization_id, outcastId)
            .then(data => {
                this.updateOutcasts();
                toast("Black listed site deleted successfully.")
            })
            .catch(err => toast.error("Something went wrong while deleting this black listed site"))
    }

    render() {
        return (
            <React.Fragment>
                <MainHeader title="Black List" />
                <Container className="mt--7" fluid>
                    <Row>
                        <Col>
                            <h1>Black List Sites</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="order-xl-2 mb-5 mb-xl-0" xl="3">
                            <Button
                                block
                                className="mb-3"
                                color="primary"
                                type="button"
                                onClick={() => this.setState({ newsgroup: null }, this.toggleModal("createNewsGroupModalOpen"))}
                            >
                                Add Site
                            </Button>
                            <Modal
                                className="modal-dialog-centered"
                                isOpen={this.state.createNewsGroupModalOpen}
                                toggle={() => this.toggleModal("createNewsGroupModalOpen")}
                            >
                                <CreateOutcast
                                    updateOutcasts={this.updateOutcasts}
                                    closeModal={() => this.toggleModal("createNewsGroupModalOpen")}
                                    // newsGroupId={this.state.newsgroup !== null ? this.state.newsgroup.id : 0}
                                    {...this.props}

                                />
                            </Modal>
                        </Col>
                    </Row>
                    <Row>
                    <Col md="8">
                        <Table className="align-items-center table-flush" responsive>
                            <thead className="thead-light">
                                <tr>
                                    <th scope="col">URL</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.outcasts.map(u => {
                                    return (
                                        <tr key={u._id}>
                                            <td scope="row"><div onClick={() => this.goToOrganization(u._id)}>{u.baseurl}</div></td>
                                            <td><a href="#" onClick={() => this.deleteOutcastConfirm(u._id)}>delete</a></td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
            </React.Fragment >
        )
    }
}


export default withRouter(Outcasts);