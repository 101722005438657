import React from "react";

import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";

import PropTypes from 'prop-types'


class MainHeader extends React.Component {
    render() {
        return (
            <React.Fragment>
                <div className="header bg-gradient-secondary pb-3 pt-5 pt-md-3 mb-8">
                    <Container fluid>
                        <h1 className="text-white">{this.props.title}</h1>
                    </Container>
                </div>
            </React.Fragment>
        )
    }
}

MainHeader.propTypes = {
    title: PropTypes.string.isRequired
}

export default MainHeader;