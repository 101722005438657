exports.formatDate = (date) => {
  return new Intl.DateTimeFormat("en-GB", {
    year: "numeric",
    month: "long",
    day: "2-digit",
  }).format(new Date(date));
};

exports.formatDateForInputField = (date) => {
  return date !== null ? date.split("T")[0] : "";
};

exports.calculateAge = (dob1) => {
  if (dob1) {
    var today = new Date();
    var birthDate = new Date(dob1);
    var age_now = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age_now--;
    }
    return age_now;
  } else {
    return "N/A";
  }
};
