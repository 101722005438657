import React from 'react'
import { Formik } from 'formik';
import PropTypes from 'prop-types'
import * as api from '../../api/api';

import { toast } from 'react-toastify';


import {
    Button,
    Card,
    CardHeader,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Modal,
    Row,
    Col
} from "reactstrap";

import ReactTagInput from "@pathofdev/react-tag-input";



class CreateNewsGroup extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            tags: [],
            name: '',
            language: []
        }


        this.closeModal = this.closeModal.bind(this);
        this.setTags = this.setTags.bind(this);
        this.setLanguages = this.setLanguages.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this)

    }

    componentDidMount() {
        if (this.props.newsGroupId !== 0) {
            api.fetchOrganizationNewsGroup(this.props.match.params.organization_id, this.props.newsGroupId).then(data => {
                console.log(data)
                this.setState({
                    name: data.name,
                    language: data.language,
                    tags: data.keywords
                })
            })
        }
    }

    setTags(newTags) {
        this.setState({
            tags: newTags,
        });
    }
    setLanguages(newLanguages) {
        this.setState({
            language: newLanguages,
        });
    }

    closeModal() {
        this.props.closeModal();
    }



    handleSubmit(values, { setSubmitting }) {
        if (this.props.newsGroupId === 0) {
            api.addOrganizationNewsGroup(this.props.match.params.organization_id, {
                name: values.name,
                language: values.language,
                keywords: this.state.tags
            }).then(data => {
                this.props.updateNewsGroups();
                this.props.closeModal();
            }).catch(err =>{
                console.log({err})
                toast(err.response.data.message)
            })
        } else {
            api.updateOrganizationNewsGroup(this.props.match.params.organization_id, this.props.newsGroupId, {
                name: values.name,
                language: values.language,
                keywords: this.state.tags
            }).then(data => {
                this.props.updateNewsGroups();
                this.props.closeModal();
            })
        }
    }


    render() {
        return (
            <React.Fragment>
                <div className="modal-header">
                    <h6 className="modal-title" id="modal-title-default">
                        Create/Edit News Group
                    </h6>
                </div>
                <Formik enableReinitialize initialValues={{ name: this.state.name, language: this.state.language }}
                    onSubmit={this.handleSubmit}>
                    {({ values, handleSubmit, handleChange, handleBlur }) => (<form onSubmit={handleSubmit}>
                        <div className="modal-body">
                            <Row>
                                <Col lg="3">
                                    <label htmlFor="name">Name</label>
                                </Col>
                                <Col lg="9">
                                    <input id="name" name="name" type="text" value={values.name} onChange={handleChange}
                                        onBlur={handleBlur} />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="3">
                                    <label htmlFor="language">language</label>
                                </Col>
                                <Col lg="9">
                                    <ReactTagInput
                                        name="language"
                                        tags={
                                            this.state.language !== undefined ? this.state.language : []
                                        }
                                        placeholder="Type and press enter"
                                        editable={true}
                                        readOnly={false}
                                        removeOnBackspace={true}
                                        onChange={this.setLanguages}
                                        validator={(value) => {
                                            return true;
                                        }}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col lg="3">
                                    <label htmlFor="tags">Tags</label>
                                </Col>
                                <Col lg="9">
                                    <ReactTagInput
                                        name="tags"
                                        tags={
                                            this.state.tags !== undefined ? this.state.tags : []
                                        }
                                        placeholder="Type and press enter"
                                        editable={true}
                                        readOnly={false}
                                        removeOnBackspace={true}
                                        onChange={this.setTags}
                                        validator={(value) => {
                                            return true;
                                        }}
                                    />
                                </Col>
                            </Row>
                        </div>
                        <div className="modal-footer">
                            <Button color="primary" type="submit">
                                Save
                            </Button>
                            <Button type="button" onClick={this.closeModal}>
                                Cancel
                            </Button>
                        </div>
                    </form>)}
                </Formik>
            </React.Fragment >
        )
    }
}

CreateNewsGroup.propTypes = {
    updateNewsGroups: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired
}

export default CreateNewsGroup;