import React, { Component } from 'react';
import {Route, Switch, Redirect } from "react-router-dom";

//import adr from "../assets/scss/argon-dashboard-react.scss";
// import withStyles from 'isomorphic-style-loader/withStyles'


import AuthLayout from './layouts/Auth.jsx'
import MainLayout from './layouts/Main.jsx'
import SiteLayout from './layouts/Site.jsx'


class App extends Component {
  render() {
    return (
        <Switch>
          <Route path="/auth" render={props => <AuthLayout {...props} />} />
          <Route path="/app" render={props => <MainLayout {...props} />} />
          <Route path="/site" render={props => <SiteLayout {...props} />} />
          <Redirect from="/" to="/app" />
        </Switch>
    );
  }
}

export default App;